.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
  min-height: 100vh;
}

.chatBody {
  margin-top: 20px;
  height: 85vh;
  overflow-y: auto;
  width: 95vw;
  max-width: 600px;
  text-align: center;
}

.form {
  width: 100vw;
  max-width: 600px;
  color: #9ea0a3;
  text-align: center;
  font-size: 12px;
}

a {
  color: #9ea0a3;
  text-decoration: underline;
}

.inputForm {
  background-color: #282c34;
  z-index: 99;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 95vw;
  max-width: 600px;
}

.iconSend {
  margin-right: 10px;
  position: absolute;
  color: #fff;
  padding: 10px 10px;
  background-color: #282c34;
  border-radius: 30px;
  cursor: pointer;
}

.iconDisabled {
  margin-right: 10px;
  position: absolute;
  color: #fff;
  padding: 10px 10px;
  background-color: #282c34;
  border-radius: 30px;
}

.iconSend:hover {
  background-color: #474d5a;
}

input {
  padding: 15px 20px;
  background-color: #282c34;
  color: #fff;
  font-size: 16px;
  border: 1px solid #4c515c;
  border-radius: 10px;
  width: 100%;
}

h1 {
  margin: 0;

  font-size: 2em;
  color: #fff;
}

.imgAssistant {
  width: 120px;
  height: 120px;

  border-radius: 120px;
}

.message {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
}

.messageContent {
  text-align: left;
  color: #ffffffa4;
  width: 100%;
  padding: 0px 20px;
}

.messageTitle {
  margin: 0;
  color: #ffffff;
  margin-bottom: 10px;
  font-weight: 700;
}

.messageUserIcon {
  width: auto;
}

.messageUserIcon img {
  width: 32px;
  height: 32px;

  border-radius: 100px;
}

#style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4c515c;
}

#style-4::-webkit-scrollbar {
  width: 10px;
  background-color: #4c515c;
}

#style-4::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}
@media screen and (max-width: 768px) {
  h1 {
    margin: 0;

    font-size: 16px;
    color: #fff;
  }
  .imgAssistant {
    width: 64px;
    height: 64px;
  }
  .inputForm {
    margin: 10px;
  }
  .chatBody {
    margin-top: 20px;
    height: 75vh;
    overflow-y: auto;
    width: 100vw;
    text-align: center;
  }
  .iconDisabled {
    border-radius: 0;
  }
  .iconSend {
    border-radius: 0;
  }
  .chatBody {
    margin-left: 10px;
    margin-right: 10px;
    width: 95vw;
  }
}
